



































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop()
  private part: any;
  private changeOpen(item: any) {
    item.open = !item.open;
    this.$forceUpdate();
  }
  private changePart(item: any, lev: any) {
    const data: any = {
      data: item,
      type: lev,
    };
    this.$emit("change", data);
  }
}
