
















































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import LeftNav from "../components/left-nav.vue";
import GujiLeftNav from "../components/guji-left-nav.vue";
import Empty from "@/components/empty.vue";
import {
  GetBookCategory,
  PostBookCategory,
  GetLeftCategory,
  GetSearchCategory,
} from "@/request/storehouse";
@Component({
  components: {
    MainHeader,
    LeftNav,
    GujiLeftNav,
    Empty,
  },
})
export default class Name extends mixins(quanxianMixin) {
  private props: any = {
    label: "category",
  };
  private source: any = "";
  private one_category: any = "";
  private two_category: any = "";
  private category_id: any = "";
  private search: any = "";
  private type: any = "";
  private category: any = [];
  private nav: any = "";
  private navData: any = [
    {
      id: "1",
      name: "解表剂",
      children: [
        {
          id: "1-1",
          name: "补气",
        },
        {
          id: "1-2",
          name: "补血",
        },
        {
          id: "1-3",
          name: "气血双补",
        },
        {
          id: "1-4",
          name: "补阴",
        },
        {
          id: "1-5",
          name: "补阳",
        },
        {
          id: "1-6",
          name: "阴阳并补",
        },
      ],
    },
    {
      id: "2",
      name: "泻下剂",
      children: [
        {
          id: "2-1",
          name: "补气",
        },
        {
          id: "2-2",
          name: "补血",
        },
        {
          id: "2-3",
          name: "气血双补",
        },
        {
          id: "2-4",
          name: "补阴",
        },
        {
          id: "2-5",
          name: "补阳",
        },
        {
          id: "2-6",
          name: "阴阳并补",
        },
      ],
    },
    {
      id: "3",
      name: "和解剂",
      children: [
        {
          id: "3-1",
          name: "补气",
        },
        {
          id: "3-2",
          name: "补血",
        },
        {
          id: "3-3",
          name: "气血双补",
        },
        {
          id: "3-4",
          name: "补阴",
        },
        {
          id: "3-5",
          name: "补阳",
        },
        {
          id: "3-6",
          name: "阴阳并补",
        },
      ],
    },
    {
      id: "4",
      name: "清热剂",
      children: [
        {
          id: "4-1",
          name: "补气",
        },
        {
          id: "4-2",
          name: "补血",
        },
        {
          id: "4-3",
          name: "气血双补",
        },
        {
          id: "4-4",
          name: "补阴",
        },
        {
          id: "4-5",
          name: "补阳",
        },
        {
          id: "4-6",
          name: "阴阳并补",
        },
      ],
    },
    {
      id: "5",
      name: "温里剂",
      children: [
        {
          id: "5-1",
          name: "补气",
        },
        {
          id: "5-2",
          name: "补血",
        },
        {
          id: "5-3",
          name: "气血双补",
        },
        {
          id: "5-4",
          name: "补阴",
        },
        {
          id: "5-5",
          name: "补阳",
        },
        {
          id: "5-6",
          name: "阴阳并补",
        },
      ],
    },
    {
      id: "6",
      name: "补益剂",
      children: [
        {
          id: "6-1",
          name: "补气",
        },
        {
          id: "6-2",
          name: "补血",
        },
        {
          id: "6-3",
          name: "气血双补",
        },
        {
          id: "6-4",
          name: "补阴",
        },
        {
          id: "6-5",
          name: "补阳",
        },
        {
          id: "6-6",
          name: "阴阳并补",
        },
      ],
    },
    {
      id: "7",
      name: "固涩剂",
    },
    {
      id: "8",
      name: "安神剂",
    },
    {
      id: "9",
      name: "开窍剂",
    },
    {
      id: "10",
      name: "祛湿剂",
    },
  ];
  private zimuShunxu: any = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  private zimusData: any = {};
  private cZimu: any = "全部";
  @Watch("$route")
  private routechange(to: any, from: any) {
    this.$router.go(0);
  }
  private get currentKu() {
    return this.$store.state.currentKu;
  }
  private get kuList() {
    return this.$store.state.kuList;
  }
  private getBor(arr: any, i: any) {
    const tolLine: any = Math.ceil(arr.length / 6);
    const curLine: any = Math.ceil((i + 1) / 6);
    if (curLine == tolLine) {
      return false;
    } else {
      return true;
    }
  }
  private handleNodeClick(data: any, node: any) {}
  private changeGujiNav(e: any) {
    this.cZimu = "全部";
    this.nav = e;
    this.getDaohang();
  }
  private changeNav(e: any) {
    this.one_category = "";
    this.two_category = "";
    if (e.type == "one") {
      this.one_category = e.data._id;
    } else {
      this.two_category = e.data._id;
    }
    this.nav = e.data;
    this.cZimu = "全部";
    this.category_id = e.data._id;
    this.getDaohang();
  }
  private changeZimuNav(val: any) {
    this.cZimu = val;
  }
  private changePart(item: any, index: any) {
    this.cZimu = item;
    return;
    const parant: any = document.getElementById("zimuBox");
    if (index == "-1") {
      parant.scrollTop = 0;
    } else {
      const children: any = document.getElementById("zimu" + index);
      if (parant) {
        if (children) {
          const parentTop = parant.offsetTop;
          const childTop = children.offsetTop;
          parant.scrollTop = childTop - parentTop - 14;
        }
      }
    }
  }
  private goMulu(item: any) {
    this.$router.push({
      path: "/main/storehouse/mulu",
      query: {
        id: item.book_id,
        source: this.source,
      },
    });
  }
  private goRead(item: any) {
    if (this.type === "古籍") {
      this.$router.push({
        path: "/main/storehouse/guji-read",
        query: {
          id: item.book_iddvo,
        },
      });
    } else {
      const d: any = {
        类型: "西医疾病",
        名称: "高血压",
        分类: "心内科",
      };
      this.$router.push({
        path: "/main/storehouse/read",
        query: {
          data: JSON.stringify(d),
        },
      });
    }
  }
  private goSearch() {
    if (!this.search) {
      this.$message.warning("请输入关键字！");
      return;
    }
    const d: any = {
      searchText: this.search,
      source: this.source,
    };
    this.$store.commit("updateCurrentKu", this.type);
    localStorage.setItem("searchFilter", "");
    this.$router.push({
      path: "/main/storehouse/search",
      query: {
        data: JSON.stringify(d),
      },
    });
  }
  private getCategory() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    if (this.type === "古籍") {
      GetBookCategory(this)
        .then((res: any) => {
          loading.close();
          this.category = res;
          if (!this.nav) {
            this.nav = res[0];
          }
          this.getDaohang();
        })
        .catch(() => {
          loading.close();
        });
    } else {
      const params: any = {
        params: {
          table_name: this.currentKu,
        },
      };
      GetLeftCategory(this, params)
        .then((res: any) => {
          loading.close();
          this.navData = res;
          if (this.navData.length == 0) {
            this.getDaohang();
          } else {
            if (!this.nav) {
              this.nav = res[0];
              this.one_category = res[0]._id;
            }
            this.getDaohang();
          }
        })
        .catch(() => {
          loading.close();
        });
    }
  }
  private getDaohang() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    if (this.type === "古籍") {
      const params: any = {
        分类: this.nav,
      };
      PostBookCategory(this, params)
        .then((res: any) => {
          loading.close();
          this.zimusData = res;
        })
        .catch(() => {
          loading.close();
        });
    } else {
      const params: any = {
        one_category: this.one_category,
        two_category: this.two_category,
        table_name: this.type,
        category_id: this.category_id,
      };
      GetSearchCategory(this, params).then((res: any) => {
        loading.close();
        let arr: any = [];
        for (const key in res) {
          if (Object.prototype.hasOwnProperty.call(res, key)) {
            if (key != "total") {
              arr.push(key);
            }
          }
        }
        this.zimuShunxu = arr;
        this.zimusData = res;
      });
    }
  }
  private refresh() {
    this.type = this.$store.state.currentKu;
    this.getCategory();
  }
  mounted() {
    this.source = this.$route.query.source;
    this.refresh();
  }
  /**
   * @description 查看实体详情
   */
  private jumpCompose(item: any) {
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/check/bentijiaoyan/compose",
      query: {
        id: item.id,
        kind: item["概念名称"],
        source: this.source + " / " + this.currentKu + " / " + item["实体名称"],
      },
    });
  }
}
